<template>
  <div class="wrap">
    <Title name="使用记录"></Title>

    <div class="container">
      <div class="search-box">
        <el-form inline>
          <el-form-item label="">
            <el-date-picker
              v-model="searchForm.time"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始使用时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss"
              end-placeholder="结束使用时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getUsageRecord(0)">查询</el-button>
          </el-form-item>
        </el-form>
      </div>

      <div class="tabel-content">
        <el-table
          :data="tableData"
          :header-cell-style="{
            background: '#f5f5f5',
            fontWeight: 500,
            color: '#666',
            padding: '12 5px',
          }"
          style="width: 100%"
        >
          <el-table-column prop="clientName" label="企业名称" align="center"></el-table-column>
          <el-table-column prop="serialNo" label="证书序列号" align="center"> </el-table-column>
          <el-table-column prop="useTime" label="使用时间" align="center"> </el-table-column>
          <el-table-column prop="orderSn" label="订单编号" align="center"> </el-table-column>
          <el-table-column prop="orderType" label="订单类型" align="center"> </el-table-column>
          <el-table-column prop="users" label="签署人" align="center"> </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button size="small" @click="openCertificate(scope.row.contractUrl)">查看合同</el-button>
            </template>
          </el-table-column>
        </el-table>

        <div class="pagination" v-if="pageManage.total > 0">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="pageManage.total"
            :page-size="pageManage.limit"
            :current-page="pageManage.page"
            @current-change="changeCurrentPage"
            prev-text="上一页"
            next-text="下一页"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from "../../component/Title";
import { tcredentialuseinfo } from "../../api";
export default {
  components: {
    Title,
  },
  data() {
    return {
      searchForm: {
        time: []
      },
      tableData: [],
      pageManage: {
        limit: 10,
        page: 1,
        total: 0,
      }
    };
  },

  created() {
    this.getUsageRecord(0);
  },

  methods: {
    changeCurrentPage(num) {
      this.pageManage.page = num;
      this.getUsageRecord(1);
    },
    getUsageRecord(type){
      let _this = this;
      if (this.searchForm.time.length === 0){
        this.searchForm.time=['', ''];
      }
      //0 代表查询按钮点击进来的，默认显示第一页数据
      //1 代表分页点进来的
      if (type==0){
        this.pageManage.page=1
      }
      tcredentialuseinfo({
        pageNo:this.pageManage.page,
        pageSize: this.pageManage.limit,
        useTime1: this.searchForm.time[0],
        useTime2: this.searchForm.time[1]
      }).then(res=>{
        _this.tableData = res.data.list
        _this.pageManage.total = res.data.total
      })
    },
    // 查看合同
    openCertificate(url) {
      window.open(url);
    },
  }
};
</script>

<style lang="less" scoped>
.wrap {
  background: #fff;

  .container {
    padding: 10px;

    .search-box {
      margin-top: 12px;
    }
  }
  .pagination {
    padding: 12px 0;
  }
}
</style>
